export const accordion = async (target) => {
  const menu = document.querySelectorAll(`${target}`);
  menu.forEach(accordionMenu => {
    accordionMenu.addEventListener('click', function(e) {
      e.stopImmediatePropagation();

        this.classList.toggle('active');

        const hiddenElement = this.querySelector('.faq-answer') || this.closest('.checklist-main-container, .legal-menu-item, .pricing-plan-card').querySelector('.content-check-list, .legal-submenu, .card-detail');
        hiddenElement.classList.toggle('active');

        hiddenElement.style.maxHeight
          ? hiddenElement.style.maxHeight = null
          : hiddenElement.style.maxHeight = 'fit-content';

    });
  });
}

export const showMoreFaqs = async () => {
  const faqButton = document.querySelector('[data-toggle="show-more"]');
  const questionButtons = document.querySelectorAll('.faqs-cards.hidden') || document.querySelectorAll('.faqs-cards.hidden.visible');

  faqButton && faqButton.addEventListener('pointerdown', () => {
    questionButtons.forEach(question => {
      const answer = question.querySelector('.faq-answer');
      question.style.maxHeight
        ? question.style.maxHeight = null
        : question.style.maxHeight = question.style.maxHeight = (question.scrollHeight + answer.scrollHeight + 32 ) + 'px';;

      question.classList.toggle('visible');
      question.classList.contains('visible')
        ? faqButton.querySelector('.texts').textContent = faqButton.dataset.textHide
        : faqButton.querySelector('.texts').textContent = faqButton.dataset.textShow;

    })
  });
};

//class will replace old code.
export class Accordions {
  constructor (accordionButton, accordionContent) {
    this.accordionButtons = document.querySelectorAll(accordionButton);
    this.accordionContent = accordionContent;
    this.menuItems = document.querySelectorAll('.legal-menu-item');
  }

  enableButtons () {
    this.accordionButtons.forEach(button => {
      button.addEventListener('click', () => {
        const submenu = button.parentElement.querySelector(this.accordionContent);
        const isActive = button.parentElement.classList.contains('active');

        if(!submenu || !window.location.href.includes(button.dataset.url) ) {
          if (button.dataset.url) window.location.href = this.setUrlAndAnchor(button.dataset.url, button.dataset.anchor);
        } else {
          this.resetMenu();
          isActive
            ? this.closeMenu(button.parentElement, submenu)
            : this.openMenu(button.parentElement, submenu)
        }
      });
    });
  };

  defaultSubMenuBehavior () {
    setTimeout(() => {
      window.location.href.includes('privacy-policy') && this.openMenu(this.menuItems[0], this.menuItems[0].querySelector('.legal-submenu'))
      window.location.href.includes('terms-of-service') && this.openMenu(this.menuItems[1], this.menuItems[1].querySelector('.legal-submenu'))
      window.location.href.includes('contracted-enterprise-customers') && this.openMenu(this.menuItems[4], this.menuItems[4].querySelector('.legal-submenu'))
    }, 500);

  }

  legalMenuDefaultBehavior(){
    if (window.location.href.includes('contracted-enterprise-customers')) sessionStorage.setItem('isEnterpriseClient', true);
    const isEnterpriseClient = sessionStorage.getItem('isEnterpriseClient');
    const userTermsOfService = document.querySelector('#terms_of_service');
    const enterpriseTermsOfService = document.querySelector('#terms_of_service_enterprise');

    if(isEnterpriseClient) {
      enterpriseTermsOfService.classList.remove('hidden');
      enterpriseTermsOfService.setAttribute('aria-hidden', false);
    } else {
      userTermsOfService.classList.remove('hidden')
      userTermsOfService.setAttribute('aria-hidden', false);
    }
  }

  openMenu (element, submenu) {
    const menuMaxHeight = `1400px`;
    element.classList.add('active');
    submenu.style.maxHeight = menuMaxHeight;
  }

  closeMenu (element, submenu) {
    element.classList.remove('active');
    submenu.style.maxHeight = 0;
  }

  setUrlAndAnchor (url, anchor) {
    if (window.location.href.includes(url) && anchor) {
      return anchor;
    } else {
      return anchor
        ? `${url}${anchor}`
        : url
    }
  };

  resetMenu () {
    this.accordionButtons.forEach(button => {
      button.parentElement.classList.remove('active');
      const submenu = button.nextElementSibling;
      if (submenu) submenu.style.maxHeight = 0;
    });
  }
}
