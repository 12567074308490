import { isUserLogged, alreadyRedirected } from './user';
import { redirectAndReset } from './redirect-reset';

const redirectBar = document.querySelector('.redirect-bar');

export const setCancelRedirectButtons = (timeInterval) => {
  const buttonCancelRedirect = document.querySelector('[data-toggle="cancel-redirect"]');

  buttonCancelRedirect.addEventListener('pointerup', () => {
    clearInterval(timeInterval);
    toggleRedirectionBar(false);
    sessionStorage.removeItem('alreadyRedirected');
  });
}

export const displayRedirectBar = () => {
  const timer = document.querySelector('.redirect-counter');
  let timerInitialValue = 10;

  if (isUserLogged && !alreadyRedirected) {
    toggleRedirectionBar(true);

    const countdown = setInterval(() => {
      timerInitialValue = timerInitialValue - 1;
      timer.textContent = timerInitialValue;

      if(timerInitialValue < 1) {
        redirectAndReset(countdown);
        sessionStorage.setItem('alreadyRedirected', true);
        timerInitialValue = 10;
      }
    }, 1000);

    setCancelRedirectButtons(countdown);
  }
}

const toggleRedirectionBar = (isActive) => {
  redirectBar.classList.toggle('active', isActive);
  document.querySelectorAll('.top-menu .sub-menu-level-1.horizontal').forEach(item => {
    item.classList.toggle('redirectbar-active', isActive);
  });
};
