import { redirectDefaultUrl } from "./user";

export function redirectAndReset(timeInterval, ) {
  const value = `; ${document.cookie}`;
  const parts = value.split('; last_session_domain=');
  let url = redirectDefaultUrl;
  document.cookie = 'redirected_to_app=false';
  sessionStorage.setItem('alreadyRedirected', true);
  clearInterval(timeInterval);

  if (parts.length === 2) {
    const lastDomain = parts.pop().split(';').shift();
    const {host, protocol} = document.location;

    if ((lastDomain !== 'desygner') && (host.match(/\./g).length < 2)) {
      url = `${protocol}//${lastDomain}.${host}/create/templates`;
    }
  }

  window.location.href = url;
}

