export async function slickSliderGenerator (slider, targetedSliderClass) {
  const breakPoints = {
    universal : {
      mobileMax: 768,
      tabletMax: 1280,
      hdMax: 1700,
      uHdMax: 4000
    },
  };

  const numberOfSlides = {
    brand: {
      mobileMax: 1,
      tabletMax: 3,
      hdMax: 4,
      uHdMax: 5
    },

    testimonials: {
      mobileMax: 1,
      tabletMax: 2,
      hdMax: 3,
      uHdMax: 4
    },

    features: {
      mobileMax: 1,
      tabletMax: 2,
      hdMax: 3,
      uHdMax: 3
    },

    workflow_4_steps_big: {
      mobileMax: 1,
      tabletMax: 1,
      hdMax: 2,
      uHdMax: 2
    },

    workflow_normal: {
      mobileMax: 1,
      tabletMax: 2,
      hdMax: 3,
      uHdMax: 3
    },

    products: {
      mobileMax: 1,
      tabletMax: 2,
      hdMax: 6,
      uHdMax: 6
    },

    industry: {
      mobileMax: 1,
      tabletMax: 1,
      hdMax: 1,
      uHdMax: 1
    }
  }

  const arrowsName = {
    brand: {
      previous: document.querySelector('.prev-arrow-brand'),
      next: document.querySelector('.next-arrow-brand')
    },

    testimonials: {
      previous: document.querySelector('.prev-arrow-testimonial'),
        next: document.querySelector('.next-arrow-testimonial')
    },

    features: {
      previous: document.querySelector('.prev-arrow-features'),
        next: document.querySelector('.next-arrow-features')
    },

    workflow_4_steps_big: {
      previous: document.querySelector('.prev-arrow-workflow-4-steps-big'),
      next: document.querySelector('.next-arrow-workflow-4-steps-big')
    },

    workflow_normal: {
      previous: document.querySelector('.prev-arrow-workflow-normal'),
      next: document.querySelector('.next-arrow-workflow-normal')
    },

    products: {
      previous: document.querySelector('.prev-arrow-products'),
      next: document.querySelector('.next-arrow-products')
    }

  };

  $(targetedSliderClass).slick({
    arrows: slider !== 'workflow_4_steps_big',
    prevArrow: slider !== 'industry' && (arrowsName[slider].previous ? arrowsName[slider].previous : false),
    nextArrow: slider !== 'industry' && (arrowsName[slider].next ? arrowsName[slider].next : false),
    autoplay: true,
    centerMode: slider === 'industry',
    dots: slider !== 'brand',
    infinite: slider === 'workflow_4_steps_big' || slider === 'workflow_normal' ? false : true,
    speed: 250,
    slidesToScroll: 1,
    fade: slider === 'industry',
    cssEase: 'ease-in-out',
    variableWidth: slider === 'workflow_4_steps_big' || slider === 'industry',
    lazyLoad: 'ondemand',
    responsive: [
      {
        breakpoint: breakPoints.universal.uHdMax,
        settings: {
          slidesToShow: numberOfSlides[slider].uHdMax,
        }
      },
      {
        breakpoint: breakPoints.universal.hdMax,
        centerMode: true,
        settings: {
          slidesToShow: numberOfSlides[slider].hdMax,
        }
      },
      {
        breakpoint: breakPoints.universal.tabletMax,
        settings: {
          slidesToShow: numberOfSlides[slider].tabletMax,
        }
      },
      {
        breakpoint: breakPoints.universal.mobileMax,
        settings: {
          slidesToShow: numberOfSlides[slider].mobileMax,
        }
      },
    ]
  });

  if (slider === 'industry') {
    $(targetedSliderClass).slick({
      customPaging: () => {
        (function(slick, index) {
          const targetText = slick.$slides.eq(index).data('title')
          return `<p class="texts BodyHeavy slick-text-menu">${targetText}</p>`;
        })
      }
    });
  }
}

export function displayBrandSliderOnLoad(){
  const brandSlider = document.querySelector('.brand-slider-container');
  brandSlider && brandSlider.classList.remove('hidden');
}
