import { getSVG } from './components/icons-loader.js';
import { slickSliderGenerator } from './components/sliders.js';
import { accordion } from './components/accordion.js';
import { displayRedirectBar } from './components/redirect-bar.js';
import { toggleRedirectBanner } from './components/redirect-banner.js';


document.addEventListener('DOMContentLoaded', async () => {
  await slickSliderGenerator('testimonials', '.testimonials-container');
  await slickSliderGenerator('features', '.features-container');
  accordion('.faqs-cards');
  getSVG();
  displayRedirectBar();
  toggleRedirectBanner();
  handleBot();
  lazyLoadVideos();
  setMonthOfferText();
  setTimeout(initFeaturesContentLinks, 300);
  setTimeout(initSliderMeasures, 300);
  initCounters();
  initVideoController();
  initSlickSlider('.contents-mobile', {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    arrows: false
  });
  initSlickSlider('.featured-blogs-container', {
    arrows: false,
    prevArrow: document.querySelector('.prev.arrows-featured-blogs-container svg'),
    nextArrow: document.querySelector('.next.arrows-featured-blogs-container svg'),
    autoplay: true,
    dots: true,
    speed: 250,
    slidesToScroll: 1,
    cssEase: 'ease-in-out',
    lazyLoad: false,
    responsive: [
      {
        breakpoint: 4000, // uHdMax,
        settings: {
          slidesToShow: 5,
          dots: false
        }
      },
      {
        breakpoint: 1700, // hdMax
        centerMode: true,
        settings: {
          slidesToShow: 5,
          dots: false
        }
      },
      {
        breakpoint: 1280, // tabletMax
        settings: {
          slidesToShow: 3,
          arrows: true
        }
      },
      {
        breakpoint: 768, // mobileMax,
        settings: {
          slidesToShow: 2,
          arrows: true
        }
      }
    ]
  });

  const planSection = document.querySelector('.plan-selection-section');
  const links = planSection.querySelectorAll('a');

  links.forEach(link => {
    link.addEventListener('click', switchPlanSelection);
  });

  function switchPlanSelection (event) {
    const link = event.currentTarget;

    planSection.querySelectorAll('.plan-selection-content').forEach(section => section.classList.remove('active'));
    links.forEach(link => link.classList.remove('active'));
    link.classList.add('active');
    planSection.querySelector(`[data-section=${link.getAttribute('data-action')}]`).classList.add('active');
  }
});

function handleBot () {
  const bookADemoButtons = document.querySelectorAll('[data-toggle=get-a-demo]');

  bookADemoButtons.forEach(button => {
    button.addEventListener('pointerup', ()=>{
      const shadow = document.querySelector('.hs-shadow-container');
      const isBotOpen = shadow && shadow.classList.contains('active');
      const searchParams = new URLSearchParams(window.location.search);
      const hasBookADemo = searchParams.has('bookademo');

      !isBotOpen && hasBookADemo && window.HubSpotConversations.widget.open();

      if (!hasBookADemo) {
        window.history.pushState({}, 'bookademo', '?bookademo=true');
        window.HubSpotConversations.widget.refresh({ openToNewThread: true });

        // Hubspot not working as in its docs and this hack is needed for now.
        setTimeout(() => window.HubSpotConversations.widget.open(), 1500);
      }
    });
  });
}

function isEmailValid (text) {
  return !!String(text)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

function sendEmail () {
  const field = document.querySelector('#email-field');

  field.blur();
  if (isEmailValid(field.value)) {
    _sendRequest('https://api.desygner.com/business/event', {
      'events': [
        {
          'type': 'website_subscriber',
          'attributes': {
            'email': field.value
          }
        }
      ]
    }).then((responseJson) => {
      document.querySelector('[data-section=email-input]').classList.add('aurelia-hide');
      document.querySelector('[data-section=email-sent]').classList.remove('aurelia-hide');
    }).catch((error) => {
      document.querySelector('[data-section=email-server-error]').classList.remove('aurelia-hide');
    });
  } else {
    document.querySelector('[data-section=email-error]').classList.remove('aurelia-hide');
  }
}

function _sendRequest (url, data, method = 'POST') {
  return fetch(url, {
    method,
    body: JSON.stringify(data),
    headers: { 'Content-type': 'application/json;charset=UTF-8' }
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error();
  });
}

function initFeaturesContentLinks () {
  const titles = document.querySelectorAll('.slider-features .titles h2');
  const content = document.querySelectorAll('.slider-features .content');
  const contents = document.querySelector('.slider-features .contents');

  function updateContentsHeight () {
    const contentElements = document.querySelectorAll('.content');
    let maxHeight = 0;
    let maxWidth = 0;

    contentElements.forEach(function (content) {
      if (content.offsetHeight > maxHeight) {
        maxHeight = content.offsetHeight;
      }
      if (content.offsetWidth > maxWidth) {
        maxWidth = content.offsetWidth;
      }
    });

    contents.style.height = maxHeight + 'px';
    contents.style.width = maxWidth + 'px';
  }

  updateContentsHeight();
  window.addEventListener("resize", updateContentsHeight);

  titles.forEach((title, index) => {
    title.addEventListener('click', () => {
      titles.forEach((title) => {
        title.classList.remove('active');
      });

      content.forEach((content) => {
        content.classList.remove('active');
      });

      content[index].classList.add('active');
      titles[index].classList.add('active');
    });
  });
}

function initSliderMeasures () {
  const container = document.querySelector('.sliderbar-section');
  const businessSection = container.querySelector('[data-section=business]');
  const enterpriseSection = container.querySelector('[data-section=enterprise]');
  const marketingMaterials = container.querySelector('#marketing_materials');
  const costSaving = container.querySelector('#cost_saving');
  const timeSaving = container.querySelector('#time_saving');

  const sliderbarRange = container.querySelector('#sliderbar-range');
  const trackOverlay = container.querySelector('.track-overlay');
  const input = container.querySelector('#sliderbar-range');

  const translations = {
    en: 'hours',
    ru: 'часы',
    es: 'horas',
    ar: 'ساعات',
    ca: 'hores',
    zh: '小时',
    nl: 'uren',
    tl: 'oras',
    fr: 'heures',
    de: 'Stunden',
    el: 'ώρες',
    he: 'שעות',
    hi: 'घंटे',
    id: 'jam',
    it: 'ore',
    ja: '時間',
    ko: '시간',
    ms: 'jam',
    pl: 'godziny',
    pt: 'horas',
    sr: 'сати',
    sl: 'ure',
    sv: 'timmar',
    th: 'ชั่วโมง',
    tr: 'saat',
    vi: 'giờ'
  };

  const setSliderbarOpacity = () => {
    const value = (sliderbarRange.value - sliderbarRange.min) / (sliderbarRange.max - sliderbarRange.min);
    const overlayWidth = value * 100;
    trackOverlay.style.width = `${overlayWidth}%`;
  };
  sliderbarRange.value = 100;
  setSliderbarOpacity();

  sliderbarRange.addEventListener('input', setSliderbarOpacity);

  input.oninput = (e) => {
    const value = parseInt(e.currentTarget.value);

    marketingMaterials.innerHTML = `${value >= 1000 ? '> ' : ''} ${value.toLocaleString()}`;
    costSaving.innerHTML = `${value >= 1000 ? '> ' : ''} $${(value * 319).toLocaleString()}`;
    timeSaving.innerHTML = `${value >= 1000 ? '> ' : ''} ${(value * 5).toLocaleString()} ${translations[getCurrentLanguage()]}`;

    if (value > 500) {
      enterpriseSection.classList.add('active');
      businessSection.classList.remove('active');
    } else {
      businessSection.classList.add('active');
      enterpriseSection.classList.remove('active');
    }
  };

  input.dispatchEvent(new Event('input'));
}

function initCounters () {
  const counterSection = document.querySelector('.counters-section');
  let initialized = false;
  const startingDate = new Date('2023-10-10');
  const currentDate = new Date();
  const days = Math.round((currentDate - startingDate) / (1000 * 60 * 60 * 24));

  const values = [{
    selector: '.counters-business-numbers',
    current: 0,
    max: 202215,
    increment: 1331
  }, {
    selector: '.counters-marketing-materials',
    current: 0,
    max: 1936626,
    increment: 12487
  }, {
    selector: '.counters-customers',
    current: 0,
    max: 12843,
    decimals: 100,
    increment: 93
  }, {
    selector: '.counters-revenue',
    current: 0,
    max: 90815,
    decimals: 100,
    increment: 639
  }];


  function updateCounter (obj, counter) {
    if (obj.current < obj.max + days * 0.01 * obj.max) {
      obj.current = obj.current + obj.increment;
      counter.forEach((c) => c.textContent = (obj.decimals
        ? parseFloat(obj.current / obj.decimals)
        : obj.current).toLocaleString());

      setTimeout(() => updateCounter(obj, counter), 17);
    }
  }

  function handleCounterScroll () {
    const scrollY = window.scrollY || window.pageYOffset;
    const counterOffsetTop = counterSection.offsetTop;
    const windowHeight = window.innerHeight;

    if (scrollY + windowHeight > counterOffsetTop && !initialized) {
      window.removeEventListener('scroll', handleCounterScroll);
      initialized = true;
      values.forEach((obj) =>{
        requestAnimationFrame(() => {
          updateCounter(obj, counterSection.querySelectorAll(obj.selector));
        });
      });
    }
  }

  window.addEventListener('scroll', handleCounterScroll);
}

function lazyLoadVideos () {
  const lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));

  if ("IntersectionObserver" in window) {
    const lazyVideoObserver = new IntersectionObserver(function(entries, observer) {
      entries.forEach(function(video) {
        if (video.isIntersecting) {
          for (const source in video.target.children) {
            let videoSource = video.target.children[source];
            if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
              videoSource.src = videoSource.dataset.src;
            }
          }

          video.target.load();
          video.target.classList.remove("lazy");
          lazyVideoObserver.unobserve(video.target);
        }
      });
    });

    lazyVideos.forEach(function(lazyVideo) {
      lazyVideoObserver.observe(lazyVideo);
    });
  }
}

function initVideoController () {
  const container = document.querySelector('.container-line.video-content .container-line-inner');
  const video = container.querySelector('.video-element');
  const playIcon = container.querySelector('.video-controller[data-type=play]');
  const pauseIcon = container.querySelector('.video-controller[data-type=pause]');
  let isPlaying = false;
  let timer;

  video.addEventListener('click', () => {
    if (isPlaying) {
      video.pause();
      isPlaying = false;
      pauseIcon.classList.add('hide');
      playIcon.classList.remove('hide');
    } else {
      video.play();
      isPlaying = true;
      playIcon.classList.add('hide');
      pauseIcon.classList.remove('hide');
    }
  });

  video.addEventListener('mouseenter', () => {
    clearTimeout(timer);
    isPlaying && pauseIcon.classList.remove('hide');
  });

  video.addEventListener('mouseleave', () => {
    timer = setTimeout(() => {
      pauseIcon.classList.add('hide');
    }, 200);
  });

  video.addEventListener('ended', () => {
    isPlaying = false;
    pauseIcon.classList.add('hide');
    playIcon.classList.remove('hide');
    video.currentTime = 0;
  });
}

function initSlickSlider (selector, options) {
  const container = document.querySelector(selector);

  const start = () => container.classList.add('grabbing');
  const stop = () => container.classList.remove('grabbing');

  document.addEventListener('mousedown', start);
  document.addEventListener('touchstart', start);

  document.addEventListener('mouseup', stop);
  document.addEventListener('touchend', stop);

  $(container).slick(options);
}

function getCurrentLanguage () {
  return document.documentElement.getAttribute('lang') || 'en';
}

function setMonthOfferText () {
  const span = document.querySelector('span.month-text');
  const month = new Date().toLocaleDateString(getCurrentLanguage(), { month: 'long' }).toUpperCase();
  span.insertAdjacentText("beforeend", month);
}
