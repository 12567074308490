export const toggleRedirectBanner = () => {
  const companyNameContainer = document.querySelectorAll('.company-name')
  const weBrandUserAlreadyalerted = sessionStorage.getItem('weBrandUserAlerted');
  const redirectFromSitesList = {
    webrand: 'webrand.com',
    criarIo: 'criar.io',
    criarCard: 'criarcartao.com.br'
  }

  if (window.location.href.includes('?from=webrand') && !weBrandUserAlreadyalerted) {
    span.innerHTML(redirectFromSitesList[webrand]);
    sessionStorage.setItem('weBrandUserAlerted', true);
    waitAndActivatePopup();
  } else {
    for (let url in redirectFromSitesList) {
      if (document.referrer.includes(url)) {
        waitAndActivatePopup();
        companyNameContainer.forEach(span => {
          span.innerHTML(redirectFromSitesList[url]);
        });
      }
    }
  }

  const waitAndActivatePopup = () => {
   const redirectedBanner = document.querySelector();
   redirectedBanner.classList.add('active')
  }
}
