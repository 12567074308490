export function getSVG() {
  if ('requestIdleCallback' in window) {
    requestIdleCallback(_fetch, { timeout: 3000 });
  } else {
    _fetch();
  }

  function _fetch () {
    fetch("https://static.webrand.com/icons/v61/sprites/symbol/sprite.svg")
      .then(iconUrl => iconUrl.text())
      .then(svg => document.body.insertAdjacentHTML("beforeend", `<div style="display: none;">${svg}</div>`))
      .catch(error => console.log(error));
  }
}
